.infibg {
    /* background: #fff; */
    padding: 16px 0px;

}

.info_number {
    /* color: #34B406; */
    /* font-size: 1.563rem; */
    font-family: Poppins;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: -0.25px;

}

.info_para {
    /* color: #1B1B1E; */
    /* color: #004A8B; */
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-align: start;
    /* 24px */
}

@media only screen and (max-width: 1440px) {}

@media only screen and (max-width: 1024px) {}

@media only screen and (max-width: 991px) {




    .infibg {
        /* background: #fff; */
        padding: 16px 0px;
    }

    .info_row>div:nth-child(3),
    .info_row>div:nth-child(4) {
        padding-top: 15px;
    }
}

@media only screen and (max-width: 768px) {
    .info_number {
        padding-top: 20px;

    }



    .infibg {
        /* background: #fff; */
        padding: 16px 0px;
    }

}

@media only screen and (max-width: 600px) {
    .info_number {
        font-size: 1.25rem;
    }

    .info_para {
        font-size: 1rem;
        padding: 16px 0px;
        text-align: center;
        font-size: 12px;
    }
}

@media only screen and (max-width: 500px) {
    .info_number {
        padding-top: 0px;
    }

    .infibg {
        padding: 0px 0px;
    }

    .info_row {
        padding: 32px 0px;
    }

    .info_main {
        padding: 0px 8px;
    }

}

@media only screen and (max-width: 425px) {}

@media only screen and (max-width: 320px) {
    .banner_bottompara {
        width: 10%;
    }
}