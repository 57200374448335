.req_blockbg {
    background: linear-gradient(135deg, #282E30 0%, #292B32 27.08%, #2A2E34 50%, #322A34 76.56%, #322E29 100%);
    padding: 72px 0px;
}

.req_headding {
    color: #FCFCFC;
    font-size: 3.07rem;
    font-family: Poppins;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.98px;
}

.req_para {
    color: #FCFCFC;
    font-size: 20px;
    font-family: Poppins;
    line-height: 150%;
    letter-spacing: -0.1px;
    padding-top: 16px;
}

.req_main_btn {
    padding-top: 72px;
}

@media only screen and (max-width:1200px) {}

@media only screen and (max-width:991px) {
    .req_blockbg {
        padding: 60px 0px;
    }

    .req_para {
        font-size: 16px;
    }

    .req_main_btn {
        padding-top: 36px;
    }

    .req_headding {
        font-size: 2.45rem;
    }
}

@media only screen and (max-width:768px) {


    .req_blockbg {
        padding: 40px 0px;
    }
}

@media only screen and (max-width:600px) {
    .req_headding {
        font-size: 2rem;
    }

    .req_main_btn {
        padding-top: 0px;
    }
}

@media only screen and (max-width:500px) {


    .req_blockbg {
        padding: 36px 8px;
    }
}

@media only screen and (max-width:375px) {}