/* .footer_block{
    padding: 72px 0px 0px 0px;
} */
.footer_logopara {
    color: #45454D;
    font-size: 16px;
    font-family: Poppins;
    line-height: 150%;
    letter-spacing: -0.35px;
    padding-top: 24px;
    margin-right: 25rem;
}

.footer_link_main {
    /* text-align: ; */
}

.head_tag {
    color: #1B1B1E;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    margin: 10px;
    letter-spacing: -0.056px;
}

.footer_top {
    padding: 72px 0px 36px 0px;
}

.footer_img_main {
    padding: 36px 0px;
}

.copy_right_txt {
    color: #6D7484;
    font-size: 16px;
    font-family: Poppins;
    line-height: 24px;
    /* padding-bottom: 36px; */
}

.footer_para {
    color: #45454D;
    font-size: 13px;
    font-family: Poppins;
    line-height: 150%;
}

.footer_img_main img {
    margin-right: 36px;
}

.footer_img_main .incrypt {
    margin-right: 32px;
    max-width: 100%;
}

.footer_link_main p {
    color: #6D7484;
    font-size: 14px;
    font-family: Poppins;
    line-height: 150%;
    letter-spacing: -0.056px;
    margin: 10px;
    cursor: pointer;
}

.bottom_para_sty {
    padding: 36px 10px 36px 10px;
    text-align: justify;
}

@media only screen and (max-width: 1200px) {
    .footer_logopara {
        margin-right: 0rem;
    }
}

@media only screen and (max-width: 991px) {
    .footer_top {
        padding: 40px 0px 40px 0px;
    }

    .footer_img_main {
        padding: 36px 0px;
    }

    .footer_img_main img {
        margin-right: 12px;
    }

    .footer_logo img {
        height: 40px;
    }

}

@media only screen and (max-width: 768px) {
    .footer_logopara {
        margin-right: 0rem;
        padding-bottom: 36px;
    }

    .footer_img_main img {
        margin-right: 16px;
        max-width: 32%;
        /* height: 24px; */
    }

    .head_tag {
        font-size: 14px;
    }

    .footer_logopara {
        font-size: 14px;
    }

    /* .footer_img_main a {
        height: 24px;
        width: 24px;
    } */
}


@media only screen and (max-width: 768px) {
    .footer_logo {
        max-width: 100%;
        width: 200px;
    }

    .footer_logopara {
        padding-bottom: 26px;
    }

    .footer_block {
        padding: 0px 8px;
    }
}

@media only screen and (max-width: 768px) {
    .footer_link_main p {
        /* font-size: 13px; */
    }

    .copy_right_txt {
        font-size: 13px;
    }
}

@media only screen and (max-width: 425px) {
    .footer_link_main p {
        /* font-size: 13px; */
    }


}