.bannerbg1 {
    background-repeat: no-repeat;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    /* height: 100vh; */
    padding-top: 66px;
}

.banner_heading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 3.81rem;
    line-height: 120%;
    /* or 73px */
    letter-spacing: -0.02em;
    text-shadow: 0px 0px 20px rgba(27, 27, 30, 0.6);
}

.banner_row {
    padding-top: 80px;
    display: flex;
    align-items: center;
}

.banner_subheading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1.57rem;
    line-height: 150%;
    letter-spacing: -0.01em;
    color: #FCFCFC;
    text-shadow: 0px 0px 20px rgba(27, 27, 30, 0.6);
    padding-top: 20px;
}

/* .banner_col {
    margin: 0px 80px 0px 0px;
} */
/* .row>*{
    padding: 0px 0px;
} */


@media only screen and (max-width: 1600px) {}



@media only screen and (max-width: 1024px) {}

@media only screen and (max-width: 991px) {

    .FormBlock {
        padding: 60px 0px;
    }

    .banner_row {
        padding-top: 60px;
    }
}

@media only screen and (max-width: 600px) {
    .banner_heading {
        font-size: 2.45rem;
    }

    .banner_subheading {
        font-size: 1.2rem;
        padding-bottom: 10px;
    }

    .banner_row {
        padding-top: 60px;
    }


}

@media only screen and (max-width: 500px) {


    .banner_col {
        margin: 0px -4px;
    }

    .banner_row {
        padding-top: 40px;
    }

    .FormBlock {
        padding: 40px 0px 0px 0px;
    }
}

@media only screen and (max-width: 425px) {

    .bannerForm {
        margin: 0px -24px;

    }

    .banner_subheading {
        font-size: 1rem;
        padding-bottom: 10px;
    }

}


/* 
@media only screen and (max-width: 1200px) {
    .banner_col {
        margin: 0px 0px;
    }
}

@media only screen and (max-width: 991px) {
    .banner_col {
        margin: 0px 0px;
    }
    .banner_col .bannerForm {
        margin: 0px -24px;
    }
}

@media only screen and (max-width: 767px) {
    .banner_heading{
        font-size: 39px;
    }
    .banner_subheading{
        font-size: 20px;
    }
    .bannerbg1{
        padding-top: 40px;
    }
} */