.box_how_main {
    /* margin: 6px; */
    padding: 16px 16px 24px;
    height: 100%;
    border-radius: 16px;
    background: #F7F7F7;
}

.box_how_main .box_img {
    padding-bottom: 24px;
}

.box_how_main .box_headding {
    color: #1B1B1E;
    font-size: 1.25rem;
    font-family: Poppins;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: -0.25px;
    padding-bottom: 8px;
}

.box_how_main .box_par {
    color: #737780;
    font-size: 1rem;
    font-family: Poppins;
    line-height: 150%;
    letter-spacing: -0.1px;
    font-weight: 400;
}

.single_box {
    padding: 0px 6px;
    height: 100%;
}

@media only screen and (max-width:991px) {
    .single_box {
        padding: 18px 0px;
    }
}

@media only screen and (max-width:768px) {
    .box_how_main .box_par {
        font-size: 1rem;
    }

    .box_how_main .box_headding {
        font-size: 1.25rem;
        padding-bottom: 8px;
    }
}

@media only screen and (max-width:425px) {
    .box_how_main .box_par {
        font-size: 0.9rem;
    }

    .box_how_main .box_headding {
        font-size: 1rem;
    }
}