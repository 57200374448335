.nav_bg {
    background: transparent;
    /* backdrop-filter: blur(20px); */
    z-index: 9999;
    position: fixed;
    left: 0;
    right: 0;
    /* border-bottom: 1px solid #4A686A14; */
    text-align: center;
    border-bottom: 1px solid transparent;
    /* border-image: linear-gradient(0.25turn, rgba(255,249,34), rgba(255,0,128), rgba(56,2,155,0)); */
    /* border-image: linear-gradient(0.25turn, rgba(235, 39, 95, 0), rgba(235, 39, 95, 1), rgba(235, 39, 95, 0)); */
    border-image-slice: 1;
    width: 100%;
    padding: 12px 0px;
    -webkit-transition: all ease-out .5s;
    -moz-transition: all ease-out .5s;
    -o-transition: all ease-out .5s;
    transition: all ease-out .5s;
}

.navbar-brand {
    padding-bottom: 0px;
}


/* .header_border{
    background: red;
    width: 1px;
    height: 20px; 
    padding: 50px; 
    
} */

.header_logo {
    font-size: 27px;
    font-weight: 700;
    color: #262250;
}

.header_link {
    font-family: 'Poppins';
    font-style: normal;
    font-size: 16px;
    letter-spacing: 0.8px;
    font-weight: 500;
    line-height: 19px;
    /* margin: 10px; */
    padding: 9px 12px;
    border-radius: 12px;
}

.header_link:hover {

    background: #363639;

}

.header_link:active {

    background: #2b2b2e;

}

.get_start_btn {
    padding: 12px 32px;
    background: #34B406;
    border-bottom: 0.5px solid rgba(252, 252, 252, 0.2);
    box-shadow: 0px 4px 12px rgba(27, 27, 30, 0.16);
    border-radius: 18px;
    border: none;
    color: #FCFCFC;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.but_display .login_img_sty {
    display: none;
}

.active_header {
    /* background-color: #fff; */
}


/* .header_link:hover {
    color: #34B406 !important;
} */

.Login_bar_main {
    display: flex;
    align-items: center;
    padding-right: 10px;
}

.but_nav_start {
    margin-left: 40px;
    display: flex;
}

.logoCenterText1 {
    color: #0C3D6F;
}

.logoCenterText1:hover {
    color: #0C3D6F;
}

.nav_parent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 0px 4px; */
}

.modal-open {
    overflow: hidden;
}

.Hamburger_boder {
    border: none;
    /* width: 40px; */
    padding: 0px;
    margin: -8px;
}

.mob_btn_main {
    display: none;
}

.hamburger_sty {
    background: #EBE9FF;
    border-radius: 16px;
    width: 40px;
    height: 40px;
    box-shadow: 0px 3px 20px 3px rgb(66 112 236 / 15%);
    border: none !important;
}

.offcanvas.offcanvas-end {
    height: auto;
    top: 0px;
    width: 100%;
}

.link-box {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins';
    padding: 6px 4px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    border-bottom: 0.5px solid rgba(151, 57, 57, 0.2);
    box-shadow: 0px 4px 12px rgba(27, 27, 30, 0.16);
    border-radius: 18px;
}

.input_main textarea {
    padding: 13px 16px 13px 16px
}

.header_logo img {
    max-width: unset;
}

@media only screen and (max-width: 991px) {
    .but_nav_start {
        margin-right: 20px;
    }

    .header_logo {
        text-align: start;
    }

    .header_logo img {
        width: 80%;
    }

    .hamburger-react {
        height: 40px !important;
        position: relative !important;
        left: -5px !important;
        top: -5px !important;
    }

    .link-box {
        flex-direction: column;
    }

    .offcanvas {
        background-clip: inherit
    }

    .offcanvas-body {
        padding: 60px 0px 0px 0px;
        background: #1b1b1e;
    }

    .offcanvas-body .but_nav_start {
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }

    .link-box {
        border-radius: 0px;
        padding-top: 30px;
    }

    .header_link {
        margin: 15px;
    }

    .but_display .login_img_sty {
        display: block;
        max-width: unset;
        background: transparent;
        box-shadow: none;
        all: unset
    }

    .but_nav_start {
        margin-left: 0px;
    }

    .hamburger_main {
        all: unset;
    }
}

@media only screen and (max-width: 600px) {
    .get_start_btn {
        padding: 12px 26px;
    }
}

@media only screen and (max-width: 425px) {
    .hamburger_sty {
        border-radius: 14px;
    }
}


@media only screen and (max-width: 375px) {
    .header_logo img {
        width: 100%;
        height: 40px;
    }

    .get_start_btn {
        padding: 8px 12px;
    }

    .but_nav_start {
        margin-right: 12px;
    }

    .but_nav_start {
        margin: 0px;
        display: flex;
    }

    .login_img_sty {
        padding: 0px 0px;
    }

    .but_display button {
        max-width: unset;
    }

    .hamburger_main {
        border: none;
        all: unset;
        margin-left: 10px;
    }

    .hamburger_sty .hamburger-react {
        width: 35px;
    }

    .hamburger-react {
        /* left: 0px !important; */
    }

    .Hamburger_boder {
        width: 65px;
    }
}

/* Header Animation css */

.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    transition: transform 400ms cubic-bezier(0.25, 0.37, 0.17, 0.96);
    z-index: 99999;
}

/* When header is not in viewport we hide it */
.header[data-scroll="out"] {
    transform: translatey(-400%);
}

/* When moving up and header we show it */
[data-scroll-dir-y="-1"] .header {
    transform: translatey(0);
}



.header {
    padding: 0.5em 0;
    color: #fff;
    /* background-color: pink; */
}