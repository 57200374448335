#core {
    fill: #DF4169;
    animation: pulse1 1.5s ease-in-out infinite;
}


@keyframes pulse1 {
    0% {
        opacity: 0;
        transform: scale(0);
    }

    0% {
        opacity: 1;
        transform: scale(25);
    }

    60% {
        opacity: 1;
        transform: scale(25);
    }

    100% {
        opacity: 0;
        transform: scale(0);
    }
}